<!-- 选择头像item  -->
<template>
  <div :class="[size=='large'?'large-main':'small-main']">
    <img :src="getBorderImg()" :class="[size=='large'?'large-border-img':'small-border-img']" />
    <img
      :src="`https://datacenter.53site.com/Werewolf/frame/${frameData.avatar_frame_id}_player.png`"
      :class="[size=='large'?'large-frame-img':'small-frame-img']"
    />
    <div :class="[size=='large'?'large-frame-name':'small-frame-name']">{{frameData.name}}</div>
  </div>
</template>

<script>
export default {
  props: ["size", "frameData", "select"],
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    // console.log("this.frameData=>", this.frameData);
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    getBorderImg: function() {
      let str = `${this.size == "large" ? "pc" : "mob"}_item_${
        this.select ? "sel" : "nor"
      }`;
      return this.imgUrl(str);
    }
  }
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.large-main {
  width: 175px;
  height: 228px;
}
img{
  -webkit-user-drag: none;
}
.small-main {
  width: 25.1vw;
  height: 32.7vw;
}
.large-frame-img {
  width: 163px;
  height: 161px;
  margin-left: 6px;
  margin-top: 7px;
  /* background-color: rosybrown; */
}
.small-frame-img {
  width: 23.4vw;
  height: 23.1vw;
  margin-left: 0.9vw;
  margin-top: 0.9vw;
}
.large-frame-name {
  color: white;
  font-size: 18px;
  width: 100%;
  text-align: center;
  /* background-color: tomato; */
  line-height: 18px;
  margin-top: 22.26px;
}
.small-frame-name {
  color: white;
  font-size: 3vw;
  width: 100%;
  text-align: center;
  /* background-color: tomato; */
  line-height: 3vw;
  margin-top: 3.1vw;
}
.large-border-img {
  width: 175px;
  height: 228px;
  position: absolute;
}
.small-border-img {
  width: 25.1vw;
  height: 32.7vw;
  position: absolute;
}
</style>